import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import Customers from "components/ui/extended/Customers";
import ConversationInboxTabs from "components/ui/extended/ConversationInboxTabs";
import Savings from "components/ui/extended/HelpCenterSavings";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import PlainHeader from "../../components/ui/base/PlainHeader";
import Testimonials from "../../components/ui/extended/Testimonials";
import CustomerServiceStories from "../../components/ui/extended/CustomerServiceStories";
import TestimonialWithImageLeonIntercom from "../../components/ui/extended/TestimonialWithImageLeonIntercom";
import TestimonialWithImageYari from "../../components/ui/extended/TestimonialWithImageYari";
import RegisterForIntercom from "components/ui/extended/RegisterForIntercom";
import ChatCTA from "../../components/ui/base/ChatCTA";

interface BestIntercomAlternativePageProps {
  location: Location;
}

export default ({ location }: BestIntercomAlternativePageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        ctaFocusId: "input-cta",
        mobileCta: true,
      }}
      location={location}
    >
      <SEO
        title={t("pages.try-customerly.best-intercom-alternative.seo.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.seo.description"
        )}
        image={{
          relativePath: "meta/why-customerly-the-best-intercom-alternative.jpg",
          alt: "The Most Competitive Intercom Alternative You Were Hoping To Find Sooner",
        }}
      />

      <PlainHeader
        title={t("pages.try-customerly.best-intercom-alternative.title")}
        description={t(
          "pages.try-customerly.best-intercom-alternative.description"
        )}
        titleMaxWidth={850}
        descriptionMaxWidth={600}
        ctaRegister={{ show: true, id: "input-cta" }}
      />

      <Customers />

      <CustomerServiceStories withoutCta />

      <Testimonials />

      <ConversationInboxTabs />

      <TestimonialWithImageLeonIntercom />

      <Savings />

      <ImageWithDescription
        title={t(
          "pages.try-customerly.best-intercom-alternative.helpCenter.title"
        )}
        description={t(
          "pages.try-customerly.best-intercom-alternative.helpCenter.subtitle"
        )}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/help-center-software-for-saas.jpg",
          alt: t(
            "pages.try-customerly.best-intercom-alternative.helpCenter.title"
          ),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t(
          "pages.try-customerly.best-intercom-alternative.autoreply.title"
        )}
        description={t(
          "pages.try-customerly.best-intercom-alternative.autoreply.subtitle"
        )}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/help-center-software-for-saas-autoreply-bot.jpg",
          alt: t(
            "pages.try-customerly.best-intercom-alternative.autoreply.title"
          ),
          position: "right",
          fullWidth: true,
        }}
      />

      <TestimonialWithImageFrancesca />

      <HorizontalStoriesMenu
        title={t(
          "pages.try-customerly.best-intercom-alternative.automate.title"
        )}
        paragraph={t(
          "pages.try-customerly.best-intercom-alternative.automate.paragraph"
        )}
        storiesPosition={"right"}
        elements={[
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.automate.incomingLead.title"
            ),
            story: {
              relativePath:
                "pages/features/live-chat-engagement/pre-chat-survey.mov",
            },
          },
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.automate.campaign.title"
            ),
            story: {
              relativePath: "pages/features/crm/contact-list-builder.mp4",
            },
          },
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.automate.behaviour.title"
            ),
            story: {
              relativePath:
                "pages/features/marketing-funnel/marketing-automation-funnel-template-builder.mp4",
            },
          },
        ]}
      />

      <HorizontalStoriesMenu
        title={t(
          "pages.try-customerly.best-intercom-alternative.satisfaction.title"
        )}
        paragraph={t(
          "pages.try-customerly.best-intercom-alternative.satisfaction.paragraph"
        )}
        storiesPosition={"left"}
        elements={[
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.satisfaction.survey.title"
            ),
            story: {
              relativePath: "pages/features/surveys/in-chat-survey.mov",
            },
          },
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.satisfaction.nps.title"
            ),
            story: {
              relativePath: "pages/features/nps/net-promoter-score.mov",
            },
          },
          {
            title: t(
              "pages.try-customerly.best-intercom-alternative.satisfaction.learn.title"
            ),
            story: {
              relativePath: "pages/features/nps/nps-feedback.mov",
            },
          },
        ]}
      />

      <RegisterForIntercom />

      <TestimonialWithImageYari />

      <ChatCTA
        title={t("pages.try-customerly.best-intercom-alternative.chatCTATitle")}
        titleMaxWidth={500}
        chatMessage={t(
          "pages.try-customerly.best-intercom-alternative.chatMessage"
        )}
      />
    </GlobalWrapper>
  );
};
